<template>
  <v-app>
    <v-app-bar
      color="#FFC3BB"
      app
      absolute
      prominent>
      <div style="font-family: 'Sacramento', serif; font-size: 48px;">Babies + Pins</div>
      
      <v-spacer></v-spacer>
      <!-- <v-btn icon x-large :to="'/shopping-cart'">
        <v-badge v-if="cartItems > 0" color="red" :content="cartItems">
          <v-icon>mdi-cart</v-icon>
        </v-badge>
        <v-icon v-else>mdi-cart</v-icon>
      </v-btn> -->
      <v-btn icon x-large @click="gotoBandP()">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn darken-4 icon x-large>
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      
    </v-app-bar>
    
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    
  }),
  computed: {
    cartItems(){
      return this.$store.getters.getItemsInCart.length;
    },
  },
  watch: {
    
  },
  methods: {
    gotoBandP() {
      window.open("https://www.instagram.com/babiesandpins/");
    },
  },
};
</script>

<style scoped>

.bp_title {
  font-family: 'Allura', cursive;
  white-space: normal;
}

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { store } from './plugins/store'

import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

import Products from './components/Products.vue'
// import Product from './components/Product.vue'
// import ShoppingCart from './components/ShoppingCart.vue'
// import BuildPin from './components/BuildPin.vue'
// import Home from './components/Home.vue'

Vue.use(VueRouter);
Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyBKVs4YR6DxSTnIbUyzeL9pNYdLdcijMnw",
  authDomain: "babies-and-pins.firebaseapp.com",
  databaseURL: "https://babies-and-pins-default-rtdb.firebaseio.com",
  projectId: "babies-and-pins",
  storageBucket: "babies-and-pins.appspot.com",
  messagingSenderId: "721235255329",
  appId: "1:721235255329:web:e72436a1e6a0d207adc360",
  measurementId: "G-NKE1Y9VSKW"
};

const app = initializeApp(firebaseConfig)
const db = getDatabase(app)

const routes = [
  // { path: '/', component: Home},
  { path: '/', component: Products}
  // { path: '/build-pin', component: BuildPin},
  // { path: '/shopping-cart', component: ShoppingCart},
  // { path: '/product/:id', component: Product}
];

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

new Vue({
  store: store,
  router: router,
  vuetify,
  db,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    itemsInCart: 0,
    cartItems: [],
    products: []
  },
  getters: {
    getAllProducts: (state) => {
        return state.products;
    },
    getItemsInCart: (state) => {
        return state.cartItems;
    },
  },
  mutations: {
    ADD_TO_CART (state, product) {
        state.cartItems.push(product);
    },
    SET_PRODUCTS_DATA(state, productData){
        state.products = productData
    }
  },
  actions: {
    setData({commit}, payload){
      commit('SET_PRODUCTS_DATA', payload)
    }
  }
});
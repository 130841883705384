<template>
  <v-container>
    <v-container grid-list-xl>
      <v-layout row wrap>
          <v-banner three-line>
            <p>Welcome! Babies+Pins is a small boutique based in Vaughan, Ontario. We specialize in creating
              unique and customizable keepsake pins.
              Our goal is to help you cherish the special moments through beautiful
              meaningful pieces that will last a lifetime.
              Babies + Pins is a family owned and handmade with love, spreading joy one pin at a time!</p>
              
              <p><b>How do I order? We are working on our online ordering system! In the meantime, please DM us on Instagram with your order.</b></p>
        </v-banner>
      </v-layout>
      <v-layout row>
        <v-container>
          <v-banner one-line>
            <b>Categories</b>
          <v-radio-group
            v-model="selected"
            row
            class="mx-2"
            @change="filterByBrand()"
          >
            <v-radio
              label="All"
              value="All"
            ></v-radio>
            <v-radio
              label="Preset Pin"
              value="Preset Pin"
            ></v-radio>
            <v-radio
              label="Pin"
              value="Pin"
            ></v-radio>
            <v-radio
              label="Charm"
              value="Charm"
            ></v-radio>
            <v-radio
              label="Gift Box"
              value="Gift Box"
            ></v-radio>
          </v-radio-group>
        </v-banner>
        </v-container>
        
      </v-layout>

      <v-layout row wrap justify-center>
        <v-flex xs10 sm5 md4 lg3 v-for="product in filteredProductsArray" :key="product.id">
          <v-card outlined color="#F8F8F8">
            <v-img :src="product.image_link" height="300px"></v-img>

            <v-card-title>
              <h4>{{ product.Title }}</h4>
            </v-card-title>
            <!-- <v-card-subtitle>
              <b>{{ product.brand}}</b>
            </v-card-subtitle> -->
            <v-card-subtitle>
              {{ product["Description "] }}
            </v-card-subtitle>
            <v-flex>
              <v-tooltip
                v-for="(item, index) in product.colors"
                :key="index"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    v-on="on"
                    :color="item.color"
                    class="lighten-1 mx-1"
                    size="36"
                  ></v-avatar>
                </template>
                <span v-if="item.color == '#FFD700'">gold</span>
                <span v-else-if="item.color == '#C0C0C0'">silver</span>
                <span v-else>{{ item.color }}</span>
              </v-tooltip>
            </v-flex>

            <v-divider></v-divider>
            <v-card-actions>
              <v-container>
                <v-col>
                  <v-row class="d-flex justify-space-between mb-6">
                    <span
                      class="body-2"
                      style="margin-top: 5px; margin-right: 1px"
                      >$</span
                    >
                    <span class="font-weight-bold text-h5">{{
                      product.price
                    }}</span>
                    <!-- <span class="body-2" style="margin-left: 2px; margin-top: 5px;">00</span> -->
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                      dark
                      class="primary darken-1"
                      icon
                      large
                      @click="preCartCheck(product)"
                    >
                      <v-icon>mdi-cart-plus</v-icon>
                    </v-btn>
                    <v-btn
                      dark
                      icon
                      small
                      class="secondary darken-2"
                      :to="'/product/' + product.ID"
                    >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn> -->
                  </v-row>
                </v-col>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-dialog v-model="dialog" persistent>
          <v-card v-if="productToAdd != null">
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-card-title class="text-h5"
                ><b>Adding {{ productToAdd.title }}</b></v-card-title
              >
              <v-btn class="ma-2" icon @click="canceAddToCart()"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>

            <v-card-text class="text-h6" v-if="productToAdd.brand == 'Pin'">
              You can add
              <b
                ><span style="color: black">{{
                  productToAdd.numCharms
                }}</span></b
              >
              charms to this pin.
              <v-layout v-if="isAddingCharms" row wrap align-content-start>
                <v-flex class="ma-2">
                  <v-avatar class="ma-2" v-for="n in 10" :key="n" size="100">
                    <img src="https://via.placeholder.com/100" alt="test" />
                  </v-avatar>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-else class="text-h6">
              Have you selected a pin?
            </v-card-text>
            <v-card-actions v-if="productToAdd.brand == 'Pin'">
              <v-btn icon v-if="isAddingCharms" @click="cancelAddingCharms()">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn
                v-if="!isAddingCharms"
                dark
                color="primary darken-1"
                @click="addCharms()"
              >
                <v-icon>mdi-star</v-icon>
                Build your Keepsake Pin
              </v-btn>
              <v-btn
                v-if="!isAddingCharms"
                dark
                color="secondary"
                @click="addProduct(productToAdd)"
                text
              >
                Add Pin Only
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-actions v-else>
              <v-btn dark color="primary darken-1" to="/build-pin">
                <v-icon>mdi-star</v-icon>
                Build a Pin
              </v-btn>
              <v-btn dark color="secondary" @click="addProduct(productToAdd)">
                Add Charm Only
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
          shaped
          :color="notificationColor"
          v-model="notification"
          :timeout="notificationTimeout"
        >
          {{ notificationText }}
        </v-snackbar>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
import { getDatabase, ref, onValue } from "firebase/database";

export default {
  data: () => ({
    dialog: false,
    productToAdd: null,
    isAddingCharms: false,
    isAddingPins: false,
    selected: "All",

    //Notification snackbar
    notification: false,
    notificationText: "",
    notificationTimeout: 2000,
    notificationColor: "green",
    db: null,
  }),
  methods: {
    canceAddToCart() {
      this.dialog = false;
      this.isAddingCharms = false;
      this.isAddingPins = false;
      // this.preCartCheckCardHeight = '20vh';
      this.addToProduct = null;
    },
    cancelAddingCharms() {
      this.isAddingCharms = false;
      // this.preCartCheckCardHeight = '20vh';
    },
    preCartCheck(product) {
      this.dialog = true;
      this.productToAdd = product;
      if (this.productToAdd.type == "Pin") {
        //how do we keep track of the charms assigned to this product
      }
    },
    addProduct(product) {
      //temporary quantity value
      product.quantity = 1;
      //TODO: 1) check if the item is already in the cart
      //      2) if the item is already in the cart, increment the amount
      //      3) add a parameter to the product
      this.$store.commit("ADD_TO_CART", product);
      //Check if successful, close dialog, notify use successfully added to cart
      this.dialog = false;
      this.notificationText = "Successfully added to cart!";
      this.notification = true;
      //TODO: When do we clear notification text data.
    },
    addCharms() {
      this.isAddingCharms = true;
      // this.preCartCheckCardHeight = '50vh'
      //TODO: When do we clear the adding charms?
    },
    getColor() {
      return "red";
    },
    setAllProducts() {
      // this.dbRef = ref(getDatabase());
      this.db = getDatabase();
      const starCountRef = ref(
        this.db,
        "1oWMNMsjq2iT9ue--lqDliyMzzL2BnHloY3X3ebw2HNE/products"
      );
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        this.$store.dispatch("setData", data);
      });
    },
    filterByBrand(){
      console.log(this.filteredProductsArray)
    }
  },
  created() {
    //check if the state of the products is empty
    //if it is empty then we dispatch a call to get all products from DB
    //call to set the state on success
    if (this.products.length == 0) {
      this.setAllProducts();
    }
  },
  computed: {
    cartItems() {
      return this.$store.getters.getItemsInCart;
    },
    products() {
      return this.$store.getters.getAllProducts;
    },
    filteredProductsArray(){
      let fproducts = Object.keys(this.products).map(i => this.products[i])
      if (this.selected == 'All') {
        return fproducts
      }

      return fproducts.filter((product) => product.brand == this.selected)
    }
  },
};
</script>